export const reviewsData = [
  {
    id: 1,
    imageSrc: require('../Images/reviews/reviews1.jpg'),
  },
  {
    id: 2,
    imageSrc: require('../Images/reviews/reviews2.jpg'),
  },
  {
    id: 3,
    imageSrc: require('../Images/reviews/reviews3.jpg'),
  },
  {
    id: 4,
    imageSrc: require('../Images/reviews/reviews4.jpg'),
  },
 
];
