export const questionsData = [
  {
    id: 1,
    title: 'Як оформити замовлення',
    text: 'Виберіть куртку вище на сторінці, натисніть "обрати". Після цього відкриється вікно в якому потрібно заповнити дані для оформлення посилки. Вкажіть дані одержувача, населений пункт та номер відділення Нової пошти або індекс Укрпошти, виберіть зручний спосіб оплати. Якщо запитань чи уточнень у Вас не залишилося, можна поставити галочку ✅ для відправки посилки без дзвінка менеджера. Ми отримаємо Ваші дані та відправимо протягом 24 годин👌. Якщо у Вас залишилися питання або бажаєте оформити замовлення за допомогою менеджера, скористайтесь способом "Швидке замовлення". Залишіть Ваші дані і наш менеджер зателефонує або напише в месенджер по вашому номеру, щоб допомогти Вам😉',
  },
  {
    id: 4,
    title: 'Як ми працюємо',
    text: 'Оформляєте заявку на сайті обравши товар або за допомогою способу швидке замовлення. Наш менеджер передзвонить Вам для уточнення деталей або напише в месенджер по Вашому номеру. **Якщо Ви заповнили всі дані одержувача в полях форми і у Вас не залишилося питань чи уточнень можна поставити галочку ✅ для відправки посилки без дзвінка менеджера. Доставимо Ваш товар протягом 1-3 днів. Отримуєте Ваш товар у відділенні Нової пошти або Укрпошти, оплачуєте посилку післяплатою або оплачуєте тільки доставку, якщо оплата за посилку вже була здійснена на картку.',
  },
  {
    id: 5,
    title: 'Оплата',
    text: 'Оплату можна зробити при отриманні посилки у відділенні Нової пошти або Укрпошти (накладний платіж). Або за передоплатою на картку (номером картки отримаєте в смс, для підтвердження потрібно точний час оплати). Фіксальний чек можна отримати по посиланню.',
  },
  {
    id: 6,
    title: 'Знижки',
    text: 'Замовте 2 та отримайте знижку ще 200 грн. Замовте 3 та отримайте додаткову знижку 250 грн. Замовте 4 та отримайте загальну додаткову знижку 300 грн.',
  },
  {
    id: 7,
    title: 'Політика повернень та обмінів',
    text: 'Ви можете повернути або поміняти куртку протягом 14 днів. При поверненні, вартість відшкодуємо Вам на картку. З появою питань завжди можете зателефонувати до нашого менеджера за телефоном 0997181662. Обмін можна здійснити на інший товар котрий запропонує менеджер. Доставку сплачує магазин коли обмін здійснюється за вени магазину',
  },
];
